.clickable {
    &:hover {
        cursor: pointer;
    }
}

.draggable {
    margin-left: auto;
    &:hover {
        cursor: grab;
    }
}

.title {
    font-weight: 600;
    border-color: transparent;
    &:hover {
        border-color: lightgrey;
    }
}
