.menu {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: stretch;
  gap: 0.5rem;
  padding: 1rem;
  border-bottom: 1px solid #d4d4d4;
  background-color: hsl(0, 0%, 97%);
  overflow-x: auto;
  scrollbar-width: thin;
}

.button {
  min-width: 2em;
  border: 1px solid #d4d4d4;
  border-radius: 10px;
  padding: 0.2em;
  background-color: white;

  &.isActive {
    background-color: hsl(0, 0%, 90%);
  }
}


.controlGroup {
  display: flex;
  align-items: center;
  justify-content: space-between; /* or 'flex-start' depending on alignment you prefer */
  width: 100%; /* or specific width based on the layout */
  max-width: 100%; /* Ensure no overflow beyond parent */
}

.buttonGroup {
  display: flex;
  flex-wrap: wrap; /* Allow buttons to wrap if needed */
  gap: 5px; /* Optional, space between buttons */
  justify-content: flex-start; /* Or 'space-between' depending on button alignment */
  flex-grow: 1; /* Allow button group to take available space */
  max-width: 100%; /* Prevent overflow of button group */
}

.buttonGroup button {
  flex-shrink: 0; /* Prevent buttons from shrinking */
  white-space: nowrap; /* Prevent text inside buttons from breaking */
}