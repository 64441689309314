.focusdiv {
    border-radius: 10px;
}

.focusdiv:hover {
    background-color: #eee;
}

.editor {

    border: 1px solid #d4d4d4;
    border-radius: 10px;
    overflow: hidden;

    /* :global so css modules doesn't modify the classname .tiptap */

    :global(.tiptap) {
        > * + * {
            margin-top: 0.75em;
        }

        *:last-child {
            margin-bottom: 0;
        }

        &:focus {
            outline: none;
        }

        ul,
        ol {
            padding: 0 1rem;
        }

        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
            line-height: 1.1;
        }

        code {
            background-color: rgba(#616161, 0.1);
            color: #616161;
        }

        pre {
            background: #0D0D0D;
            color: #FFF;
            font-family: 'JetBrainsMono', monospace;
            padding: 0.75rem 1rem;
            border-radius: 0.5rem;

            code {
                color: inherit;
                padding: 0;
                background: none;
                font-size: 0.8rem;
            }
        }

        img {
            max-width: 100%;
            height: auto;
        }

        blockquote {
            padding-left: 1rem;
            border-left: 2px solid rgba(#0D0D0D, 0.1);
        }

        hr {
            border: none;
            border-top: 2px solid rgba(#0D0D0D, 0.1);
            margin: 2rem 0;
        }
    }

}